body {
  @include body-type;
  @include content-font-size;
  background: $c-white;
  color     : $c-dark;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

img {
  display  : block;
  max-width: 100%;
}

html {
  height: 100%;
}

.ember-application {
  min-height: 100vh!important;
  height    : auto!important;
  overflow  : auto!important;

  > .ember-view {
    &:not(.liquid-destination) {
      min-height: 100vh!important;
      height    : auto!important;
    }
  }
}