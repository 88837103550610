.uxs-form__icon {
  @include border-color-modifiers;
  @include text-color-modifiers($prefix:'');
  $size        : rem-size($baseline*1.5);
  @extend .uxs-icon;
  // background   : $c-primary-5;
  border-width : 1px;
  border-style : solid;
  border-color : $c-light;
  border-radius: 0;
  // color        : $c-dark;
  cursor       : pointer;
  display      : block;
  height       : $size;
  line-height  : $size;
  text-align   : center;
  width        : $size;

  &--prefix {
    border-right: 0;
  }

  &--suffix {
    border-left: 0;
  }

  &__icon~.uxs-form__icon {
    margin-left: 0;
  }
}