.uxs-list {
  &__notice {
    @include background-color-modifiers();
    @include text-color-modifiers();
    background    : $c-light;
    display       : flex;
    flex-direction: row;
    padding       : rem-size($baseline*0.5);
    @include mq(tablet) {
      padding: rem-size($baseline*0.5) rem-size($baseline*1);
    }
  }

  &__notice-icon {
    opacity   : 0.2;
    margin    : 0 rem-size($baseline*1.0) 0 rem-size($baseline*0.5);
    text-align: center;
  }

  &__notice-text {
    @include type-detail;
    flex     : 1 1 auto;
    max-width: none;
  }

  &__notice-action {
    @include type-detail;
    @include bold-body-type;
    cursor        : pointer;
    text-transform: uppercase;
  }
}