.ac {
  text-align: center;
}

.ar {
  text-align: right;
}

.al {
  text-align: left;
}

.aj {
  text-align: justify;
}
