.uxs-toolbar {
  display       : flex;
  flex-direction: row;
  padding       : rs(0.5);
  @include background-color-modifiers();
  @include mq(tablet) {
    padding: rs(0.5) rs(1);
  }

  &__title {
    margin        : 0;
    text-transform: none;
    line-height   : rem-size($baseline);
    font-weight   : 400;
  }

  &__back,
  &__title {
    margin-right: rem-size($baseline*0.5);
    line-height : rem-size($baseline);
  }

  &__spacer {
    flex: 1 1 auto;
  }

  &__action {
    margin-left: rem-size($baseline*0.25);
    line-height: rem-size($baseline);
  }

  &__search {
    &--light,
    &--mid,
    &--white {
      // durty hack - bem no like
      .uxs-search__close,
      .uxs-search__input {
        border-bottom-color: $c-dark;
        color              : $c-dark;
      }
    }
  }
}