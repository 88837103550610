/*
 * ITCSS ~ https://www.xfive.co/blog/itcss-scalable-maintainable-css-architecture/
 * -----
 * Settings – used with preprocessors and contain font, colors definitions, etc.
 * Tools – globally used mixins and functions.
 * Generic – reset and/or normalize styles, box-sizing definition
 * Elements – styling for bare HTML elements
 * Objects – class-based selectors which define undecorated design patterns
 * Components – specific UI components.
 * Utilities – utilities and helper classes - override anything before!
 */
/* */
$theme: "sauce"!default;
@import "uxs/tools.functions";
@import "uxs/settings.variables";
@import "uxs/include.base";
@import "uxs/include.tools";
@import "uxs/include.vendor";
@import "uxs/include.elements";
@import "uxs/include.objects";
@import "uxs/include.components";
@import "uxs/include.trumps";
@import "theme/include.theme";