.uxs-div {
  @include align-modifiers;
  @include font-size-modifiers;
  @include line-height-modifiers;
  @include text-color-modifiers($prefix:'c-');
  @include background-color-modifiers();
  @include weight-modifiers;

  &--clear:after {
    content: "";
    display: table;
    clear  : both;
  }
}