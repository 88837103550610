// body {
//   $c-default: $c-primary;
//
//   .ember-power-select-trigger {
//     background : $c-white;
//     border     : rem-size(1px) solid $c-default;
//     color      : $c-dark;
//     font-weight: 300;
//     padding    : rem-size(($baseline*0.25)-1px) rem-size(($baseline*2)-1px) rem-size(($baseline*0.25)-1px) rem-size(($baseline*0.5)-1px);
//
//     &:before {
//       content    : "";
//       display    : table;
//       clear      : both;
//       width      : rem-size($baseline*1.5);
//       height     : rem-size($baseline*1.5);
//       // background : $c-default-5;
//       border-left: rem-size(1px) solid $c-default;
//       position   : absolute;
//       top        : 0;
//       right      : 0;
//     }
//   }
//
//   .ember-power-select-placeholder,
//   .ember-power-select-selected-item {
//     margin-left : 0;
//     margin-right: rem-size($baseline*0.25);
//   }
//
//   .ember-power-select-status-icon {
//     border-color: $c-default transparent transparent transparent;
//     border-width: rem-size(10.825px) rem-size($baseline * 0.25) 0 rem-size($baseline * 0.25);
//     right       : rem-size($baseline * 0.5);
//     position    : absolute;
//   }
//
//   .ember-power-select-trigger:after {
//     // content   : "";
//     // display   : table;
//     // clear     : both;
//     // width     : 37.5px;
//     // height    : 37.5px;
//     // background: rgba(#ff69b4, 0.5);
//     // position  : absolute;
//     // top       : 0;
//     // right     : 0;
//   }
//
//   .ember-power-select-clear-btn {
//     $size      : rem-size(($baseline*1.5)-2);
//     color      : $c-grey;
//     line-height: $size;
//     text-align : center;
//     height     : $size;
//     width      : rem-size($baseline*1.5);
//     top        : 0;
//     right      : $size;
//   }
//
//   .ember-power-select-group-name {
//     @include type-detail;
//     @include bold-body-type;
//     // background    : $c-default-5;
//     border-bottom : rem-size(1px) solid $c-default;
//     color         : $c-default;
//     display       : block;
//     line-height   : rem-size(($baseline*1.5)-1px);
//     padding       : 0 rem-size($baseline*0.5);
//     text-transform: uppercase;
//     max-width     : none;
//   }
//
//   .ember-power-select-search-input,
//   .ember-power-select-search-input:focus {
//     // background-color   : $c-default-4;
//     border             : rem-size(1px) solid $c-default;
//     border-bottom-width: rem-size(2px);
//     @include input-placeholder {
//       opacity: 0.4;
//     }
//   }
//
//   .ember-power-select-option {
//     color        : $c-dark;
//     margin-bottom: 0;
//
//     &[aria-current="true"],
//     &[aria-selected="true"] {
//       color: $c-dark;
//     }
//   }
//
//   ul ul .ember-power-select-option:last-child {
//     border-bottom-color: $c-default;
//   }
// }