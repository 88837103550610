.uxs-fab {
  @extend .material-icons;
  @include card-shadow(1);
  @include transition-all;
  $size        : rem-size($baseline*2.5);
  $offset      : rem-size($baseline*.75);
  background   : $c-accent;
  border-radius: 100%;
  bottom       : $offset;
  color        : $c-white;
  cursor       : pointer;
  display      : block;
  height       : $size;
  right        : $offset;
  line-height  : $size;
  position     : fixed;
  text-align   : center;
  width        : $size;

  &:focus,
  &:hover {
    @include card-shadow(2);
  }

  &--disabled {
    background: $c-accent-3;
    cursor    : not-allowed;

    &:focus,
    &:hover {
      @include card-shadow(1);
    }
  }
}