.uxs-list {
  &__subheading {
    @include type-subheading;
    @include text-color-modifiers($prefix:'');
    @include contrast-border-color-modifiers($dark-color:rgba($c-dark,0.4), $light-color:rgba($c-white,0.4),$prefix:'parent-');
    border-color  : $c-light;
    display       : flex;
    flex-direction: row;
    padding       : rem-size($baseline*.75) rem-size($baseline*0.5) rem-size($baseline*0.25);
    text-transform: uppercase;
    @include mq(tablet-wide) {
      padding-left : rem-size($baseline*1);
      padding-right: rem-size($baseline*1);
    }

    &--bordered {
      border-bottom-style: solid;
      border-bottom-width: rem-size(1px);
      line-height        : rem-size($baseline - 1);
    }

    &-text {
      flex: 1 1 auto;
    }

    &-icon {
      @extend .uxs-icon;

      &--medium {
        margin: 0 rs(0.5);

        &:first-child {
          margin-right: rs(1);
        }

        &:last-child {
          margin-left : rs(0.5);
          margin-right: 0;
        }
      }
    }

    &-toggle {
      position   : relative;
      line-height: rs(1);

      .x-toggle-container {
        bottom  : 0;
        position: absolute;
      }
      @each $modifier, $color in $defaultColors {
        &--#{$modifier} .x-toggle:checked + label > .x-toggle-ios.x-toggle-btn {
          background-color: $color;
        }
      }
    }
  }
}

.x-toggle-component {
  width: rs(3);
}

.x-toggle-ios {
  padding: rem-size(3px);
  height : rs(1.5);
  width  : rs(3);
}

.x-toggle:checked + label > .x-toggle-ios.x-toggle-btn {
  background: $c-light;
}

.x-toggle:checked + label > .x-toggle-ios.x-toggle-btn {
  background: $c-accent;
}

.x-toggle-component.x-toggle-focused:checked .x-toggle-btn:not(.x-toggle-disabled)::after,
.x-toggle-component.x-toggle-focused:checked .x-toggle-btn:not(.x-toggle-disabled)::before {
  box-shadow: none!important;
}