.uxs-avatar {
  @include contrast-background-color-modifiers();
  @include background-color-modifiers();

  &--rounded {
    border-radius: 100%;
  }

  &--border {
    border: rem-size(1px) solid $c-light;
    @include border-color-modifiers();
  }
}