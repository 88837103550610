.uxs-pill {
  @include body-type;
  display       : flex;
  flex-direction: row;
  float         : left;
  background    : $c-mid;
  color         : $c-white;
  margin-right  : rem-size(2px);
  padding       : 0 4%;
  @include background-color-modifiers();

  &--rounded {
    border-radius: rem-size($baseline*0.75);
  }

  &--disabled {
    cursor        : not-allowed;
    pointer-events: none;
    opacity       : 0.6;
  }
  @include font-size-modifiers;
  $sizes        : (
    "giant" : $baseline*4.0,
    "huge"  : $baseline*3.0,
    "large" : $baseline*2.0,
    "medium": $baseline*1.0,
    "small" : $baseline*0.75,
    "tiny"  : $baseline*0.675,
  );
  @each $modifier, $size in $sizes {
    &--#{$modifier} {
      line-height  : rem-size($size - 2px);
      border-radius: rem-size($size);
      margin-bottom: rem-size(1px);
      margin-top   : rem-size(1px);
    }
  }
  //
  // &--small {
  //   line-height  : rs(0.75);
  //   margin-top   : rs(0.125);
  //   margin-bottom: rs(0.125);
  // }
  //
  // &--tiny {
  //   line-height  : rs(0.675);
  //   margin-top   : rs(0.1625);
  //   margin-bottom: rs(0.1625);
  // }
  &--with-prefix-icon,
  &--with-suffix-icon {
    display: flex;
  }

  &--with-prefix-icon {
    padding-left: 0;
  }

  &--with-suffix-icon {
    padding-right: 0;
  }
}

.uxs-pill__icon {
  color     : $c-white;
  text-align: center;

  &.uxs-icon--prefix {
    margin-right: rs(0.125);
  }

  &.uxs-icon--suffix {
    margin-left: rs(0.125);
  }

  &--white {
    color: $c-dark;
  }

  &:focus::before,
  &:hover::before {
    display: none;
  }
  $sizes    : (
    "giant" : $baseline*4.0,
    "huge"  : $baseline*3.0,
    "large" : $baseline*2.0,
    "medium": $baseline*1.0,
    "small" : $baseline*0.75,
    "tiny"  : $baseline*0.675,
  );
  @each $modifier, $size in $sizes {
    &--#{$modifier} {
      line-height: rem-size($size);
      font-size  : rem-size($size - 6px);
      height     : rem-size($size - 6px);
      width      : rem-size($size -6px);
    }
  }
}