.uxs-app {
  display       : flex;
  flex-direction: column;
  align-items   : center;
  min-height    : 100vh;
  overflow      : auto;

  &--has-fixed-navbar {
    $height    : #{rs(2)};
    padding-top: $height;
    @supports (padding-top: env(safe-area-inset-top)) {
      padding-top: calc(env(safe-area-inset-top) + #{$height});
    }
  }

  &__padder {
    height: rem-size($baseline*2.5);
    width : 100%;
  }
}