.filter {
  background : $c-white;
  border-left: rem-size(1px) solid $c-light;
  position   : relative;
  height     : calc(100% - 50px);
  margin-top : rem-size($baseline*2);
  width      : 100%;
  @include mq(tablet) {
    // height    : 100%;
    width: 33.3%;
  }
  @include mq(desktop) {
    // height: 100%;
    width: 25%;
  }

  &__overlay {}

  &__wrapper {}

  &__container {}

  &__subheading {
    border-bottom : rem-size(1px) solid $c-light;
    padding       : rem-size($baseline*0.5) rem-size($baseline) rem-size(($baseline*0.5)-1px);
    text-transform: uppercase;
  }

  &__close {
    position: absolute;
    top     : rem-size($baseline*0.5);
    right   : rem-size($baseline*1);
  }

  &__form {
    padding: rem-size($baseline*1);
  }

  &__control {
    &--range {
      min-height: rem-size($baseline*3.5);
    }

    &--date {
      min-height: rem-size($baseline*2);
    }
  }
}