.uxs-swatch {
  border        : 1px solid $c-light;
  display       : flex;
  flex-direction: row;
  flex-wrap     : wrap;
  margin        : rem-size(-2px);
  @include mq(tablet) {
    flex-direction: row;
    flex-wrap     : nowrap;
  }

  &__item {
    // @include drop-shadow;
    align-items    : flex-start;
    display        : flex;
    flex-direction : column;
    justify-content: flex-end;
    height         : rem-size(($baseline*6)-2px);
    width          : 50%;
    padding        : rem-size($baseline*.25);
    position       : relative;
    @include mq(tablet) {
      width: auto;
      flex : 1;
    }
  }

  &__var {
    @include type-detail;
    display: block;
  }
}