.summary {
  padding: 0 rs(0.5);
  @include mq(tablet) {
    padding: 0 rs(1);
  }

  &__image {
    margin-top: rs(1);
  }

  &__title {
    @include type-subheading;
  }

  &__subtitle {
    @include type-detail;
    margin-bottom: rs(1);
  }

  &__data {
    border-top: rem-size(1) solid rgba($c-light, 0.4);
    padding   : rs(0.25,1) 0 0;
    margin    : rs(0.25) 0 0;
  }

  &__data-column,
  &__data-row {
    display      : flex;
    border-bottom: rem-size(1) solid rgba($c-light, 0.4);
    padding      : 0 0 rs(0.25,1);
    margin       : 0 0 rs(0.25);
  }

  &__data-row {
    flex-direction: row;
  }

  &__data-column {
    flex-direction: column;
  }

  &__data-label {
    @include type-detail;
    @include bold-body-type;
  }

  &__data-value {
    @include type-detail;
    text-align: right;
    flex      : 1 1 auto;
    max-width : none;
  }

  &__data-link {
    @include type-detail;
    color     : $c-accent;
    text-align: left;
    flex      : 1 1 auto;
    @include text-truncate;
  }
}