.uxs-avatar {
  display: block;
  width  : rem-size($baseline*2);
  height : rem-size($baseline*2);

  &--rounded {}

  &--border {}

  &--small {
    $size      : rem-size($baseline*1);
    line-height: $size;
    height     : $size;
    width      : $size;
  }

  &--large {
    $size      : rem-size($baseline*3);
    line-height: $size;
    height     : $size;
    width      : $size;
  }

  &--huge {
    $size      : rem-size($baseline*4);
    line-height: $size;
    height     : $size;
    width      : $size;
  }

  &--giant {
    $size      : rem-size($baseline*6);
    line-height: $size;
    height     : $size;
    width      : $size;
  }
}