.uxs-text-avatar {
  $size          : rem-size($baseline*2);
  align-items    : center;
  display        : flex;
  flex-direction : column;
  height         : $size;
  justify-content: space-around;
  line-height    : $size;
  width          : $size;

  &--rounded {}

  &--border {}

  &--small {
    $size      : rem-size($baseline*1);
    line-height: $size;
    font-size  : $s-small;
    height     : $size;
    width      : $size;
  }

  &--large {
    $size      : rem-size($baseline*3);
    line-height: $size;
    font-size  : $s-large;
    height     : $size;
    width      : $size;
  }

  &--huge {
    $size      : rem-size($baseline*4);
    line-height: $size;
    font-size  : $s-huge;
    height     : $size;
    width      : $size;
  }

  &--giant {
    $size      : rem-size($baseline*6);
    line-height: $size;
    font-size  : $s-giant;
    height     : $size;
    width      : $size;
  }

  &__large-text,
  &__small-text {
    text-align: center;
    display   : block;
    margin    : 0;
    position  : relative;

    &--giant {
      line-height: rs(3);
    }

    &--huge {
      line-height: rs(2);
    }

    &--large {
      line-height: rs(1.5);
    }

    &--medium {
      line-height: rs(1);
    }

    &--small {
      line-height: rs(0.5);
    }

    &:last-child {
      bottom: rs(0);
    }
  }

  &__large-text {
    font-size: 90%;

    &--giant:first-child {
      top: rs(1);
    }

    &--giant:last-child {
      bottom: rs(1);
    }

    &--huge:first-child {
      top: rs(0.75);
    }

    &--huge:last-child {
      bottom: rs(0.75);
    }

    &--large:first-child {
      top: rs(0.5);
    }

    &--large:last-child {
      bottom: rs(0.5);
    }

    &--medium:first-child {
      top: rs(0.375);
    }

    &--medium:last-child {
      bottom: rs(0.375);
    }

    &--small:first-child {
      top: rs(0.125);
    }

    &--small:last-child {
      bottom: rs(0.125);
    }
  }

  &__small-text {
    @include text-truncate;
    font-size: 25%;
    width    : 75%;
    // margin-bottom: 12.5%;
  }
}