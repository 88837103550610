.material-icons {
  font-family            : 'Material Icons';
  font-weight            : normal;
  font-style             : normal;
  font-size              : 24px;
  /* Preferred icon size */
  display                : inline-block;
  line-height            : rem-size($baseline);
  text-transform         : none;
  letter-spacing         : normal;
  word-wrap              : normal;
  white-space            : nowrap;
  direction              : ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing : antialiased;
  /* Support for Safari and Chrome. */
  text-rendering         : optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings  : 'liga';
}
@mixin material-icon {
  font-family            : 'Material Icons';
  font-weight            : normal;
  font-style             : normal;
  font-size              : 24px;
  /* Preferred icon size */
  display                : inline-block;
  line-height            : rem-size($baseline);
  text-transform         : none;
  letter-spacing         : normal;
  word-wrap              : normal;
  white-space            : nowrap;
  direction              : ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing : antialiased;
  /* Support for Safari and Chrome. */
  text-rendering         : optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings  : 'liga';
}