/*
 * Note that this is toastr v2.1.3, the "latest" version in url has no more maintenance,
 * please go to https://cdnjs.com/libraries/toastr.js and pick a certain version you want to use,
 * make sure you copy the url from the website since the url may change between versions.
 * */
.toast-title {
  @include type-subheading;
}

.toast-message {
  @include type-detail;
  text-align   : center;
  -ms-word-wrap: break-word;
  word-wrap    : break-word;
  @include mq(tablet) {
    @include type-content;
  }
}

.toast-message a,
.toast-message label {
  color: $c-white;
}

.toast-message a:hover {
  color          : $c-grey;
  text-decoration: none;
}

.toast-close-button {
  position   : relative;
  float      : right;
  color      : $c-white;
  position   : relative;
  visibility : hidden;
  // font-size  : $s-medium;
  // font-weight: bold;
  // line-height: rem-size($baseline);
  height     : rem-size($baseline);
  line-height: rem-size($baseline);
  width      : rem-size($baseline);

  &::after {
    @extend .material-icons;
    @include transition-all;
    color      : $c-white;
    content    : 'close';
    position   : absolute;
    top        : rem-size($baseline*-0.25);
    left       : rem-size($baseline*-0.25);
    height     : rem-size($baseline*1.5);
    line-height: rem-size($baseline*1.5);
    visibility : visible;
    width      : rem-size($baseline*1.5);
  }

  &:focus::after,
  &:hover::after {
    background-color: rgba($c-dark, 0.2);
    border-radius   : rem-size($baseline);
    text-decoration : none;
    cursor          : pointer;
  }
}
/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding           : 0;
  cursor            : pointer;
  background        : transparent;
  border            : 0;
  -webkit-appearance: none;
}

.toast-top-center {
  top  : 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right : 0;
  width : 100%;
}

.toast-top-full-width {
  top  : 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right : 0;
  width : 100%;
}

.toast-top-left {
  top : rem-size($baseline*0.5);
  left: rem-size($baseline*0.5);
}

.toast-top-right {
  top  : rem-size($baseline*0.5);
  right: rem-size($baseline*0.5);
}

.toast-bottom-right {
  right : rem-size($baseline*0.5);
  bottom: rem-size($baseline*0.5);
}

.toast-bottom-left {
  bottom: rem-size($baseline*0.5);
  left  : rem-size($baseline*0.5);
}

#toast-container {
  position      : fixed;
  z-index       : 999999;
  padding       : 0 rem-size($baseline);
  pointer-events: none;

  & > div {
    @include card-shadow(2);
    position      : relative;
    pointer-events: auto;
    overflow      : hidden;
    margin        : 0 0 rem-size($baseline*0.5);
    padding       : rem-size($baseline * 0.5);
    color         : $c-white;
  }

  &.toast-bottom-center > div,
  &.toast-top-center > div {
    margin-left : auto;
    margin-right: auto;
  }
}

.toast {
  background-color: $c-dark;
}

.toast-success {
  background-color: $c-success;
}

.toast-error {
  background-color: $c-error;
}

.toast-info {
  background-color: $c-dark;
}

.toast-warning {
  background-color: $c-warning;
}

.toast-progress {
  position        : absolute;
  left            : 0;
  bottom          : 0;
  height          : rem-size($baseline*0.25);
  background-color: $c-dark;
  opacity         : 0.4;
}
@include mq(tablet) {
  #toast-container > div {
    margin-left : auto;
    margin-right: auto;
    width       : $max-width-content;

    &.toast-bottom-full-width > div,
    &.toast-top-full-width > div {
      width       : 100%;
      margin-left : auto;
      margin-right: auto;
    }
  }
}