.uxs-list-item {
  @include transition-all;
  @include contrast-border-color-modifiers($dark-color:rgba($c-dark,0.4), $light-color:rgba($c-white,0.4));
  align-items   : center;
  border-color  : $c-light;
  display       : flex;
  flex-direction: row;
  padding       : rem-size($baseline * 0.2) rs(0.5);
  @include mq(tablet) {
    padding-left : rs(1);
    padding-right: rs(1);
  }

  &--bordered {
    border-bottom-style: solid;
    border-bottom-width: rem-size(1px);
  }

  &--clickable {
    cursor: pointer;
  }

  &--one-line {
    height: rem-size($baseline * 2);
  }

  &--two-lines {
    height: rem-size($baseline * 3);
  }

  &--three-lines {
    height: rem-size($baseline * 4);
  }

  &:hover {
    background-color: rgba($c-light, .2);
  }

  &__body {
    flex: 1 1 auto;

    &--clickable {
      cursor: pointer;
    }
  }

  &__aside {
    &:first-child {
      margin-right: rem-size($baseline*.5);
    }

    &:last-child {
      margin-left: rem-size($baseline*.5);
    }

    &--clickable {
      cursor: pointer;
    }
  }

  &__avatar {}

  &__content,
  &__detail {
    margin-bottom: 0;
  }

  &__detail {
    line-height: rem-size($baseline*.75);
  }
}