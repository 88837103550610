.uxs-icon-avatar {
  @include material-icon;
  $size      : rem-size($baseline*2);
  align-items: center;
  display    : block;
  height     : $size;
  line-height: $size;
  text-align : center;
  width      : $size;

  &--rounded {}

  &--border {}

  &--selected {}

  &--small {
    $size      : rem-size($baseline*1);
    line-height: $size;
    font-size  : $s-small;
    height     : $size;
    width      : $size;
  }

  &--large {
    $size      : rem-size($baseline*3);
    line-height: $size;
    font-size  : $s-large;
    height     : $size;
    width      : $size;
  }

  &--huge {
    $size      : rem-size($baseline*4);
    line-height: $size;
    font-size  : $s-huge;
    height     : $size;
    width      : $size;
  }

  &--giant {
    $size      : rem-size($baseline*6);
    line-height: $size;
    font-size  : $s-giant;
    height     : $size;
    width      : $size;
  }
}