.uxs-navbar {
  // color          : $c-white;
  display        : flex;
  flex-direction : row-reverse;
  flex-direction : row;
  justify-content: space-between;
  height         : rem-size($baseline*2);
  max-height     : rem-size($baseline*2);
  overflow       : hidden;
  position       : sticky;
  top            : 0;
  z-index        : 5;
  @include mq(tablet) {
    flex-direction: row;
  }

  &--fixed {
    left    : 0;
    position: fixed;
    top     : 0;
    width   : 100%;
  }

  &__toggle {
    // color      : $c-white;
    $size          : rem-size(($baseline*2)-1);
    justify-content: center;
    align-items    : center;
    display        : flex!important;
    height         : $size!important;
    line-height    : $size;
    text-align     : center;
    width          : rem-size($baseline*2)!important;
    @include mq(tablet) {
      display   : none!important;
      visibility: none;
    }
  }

  &__logo {
    // @include type-content;
    line-height: rem-size($baseline*2);
    flex       : 1 1 auto;
    margin     : 0 rem-size($baseline*2) 0 0;
    max-width  : none;
    text-align : center;
    @include mq(tablet) {
      flex  : none;
      margin: 0 rem-size($baseline*0.5);
    }
    height     : rs(2);
    text-align : center;

    &-svg {
      height: 100%;
    }

    &-img {
      height: 100%;
    }

    &:hover {
      opacity: 0.8;
      color  : $c-white;
    }
  }

  &__desktop {
    display   : none;
    padding   : rem-size($baseline *0.25);
    visibility: hidden;
    @include mq(tablet) {
      display   : block;
      visibility: visible;
    }
  }

  &__mobile {
    @include mq(tablet) {
      display   : none;
      visibility: hidden;
    }
  }

  &__drawer {
    animation : slideInLeft 0.5s;
    background: $c-primary;
    color     : $c-white;
    width     : 80%;
    max-width : $max-width-content;
    height    : 100vh;
    padding   : rem-size($baseline*0.5) rem-size($baseline*0.25);
    overflow  : auto;
  }

  &__drawer-overlay {
    animation : fadeIn 0.5s;
    background: rgba($c-dark,0.8);
    cursor    : pointer;
  }
}