@import "../uxs.vendor"; // // Variables
// Backgrounds
//
$ember-power-select-background-color: $c-white;
$ember-power-select-disabled-background-color: $c-mid;
$ember-power-select-multiple-selection-background-color: $c-white;
$ember-power-select-highlighted-background: $c-light;
$ember-power-select-selected-background: $c-mid; // Texts
$ember-power-select-text-color: $c-dark;
$ember-power-select-placeholder-color: $c-mid;
$ember-power-select-highlighted-color: $c-dark;
$ember-power-select-disabled-option-color: $c-mid;
$ember-power-select-multiple-selection-color: $c-dark; // Borders
$ember-power-select-border-color: $c-light;
$ember-power-select-focus-border-color: $ember-power-select-border-color;
$ember-power-select-default-border: 1px solid $ember-power-select-border-color;
$ember-power-select-default-focus-border: 1px solid $ember-power-select-focus-border-color;
$ember-power-select-trigger-border: $ember-power-select-default-border;
$ember-power-select-trigger-border-top: $ember-power-select-trigger-border;
$ember-power-select-trigger-border-bottom: $ember-power-select-trigger-border;
$ember-power-select-trigger-border-right: $ember-power-select-trigger-border;
$ember-power-select-trigger-border-left: $ember-power-select-trigger-border;
$ember-power-select-active-trigger-border: $ember-power-select-default-focus-border;
$ember-power-select-active-trigger-border-top: $ember-power-select-active-trigger-border;
$ember-power-select-active-trigger-border-bottom: $ember-power-select-active-trigger-border;
$ember-power-select-active-trigger-border-right: $ember-power-select-active-trigger-border;
$ember-power-select-active-trigger-border-left: $ember-power-select-active-trigger-border;
$ember-power-select-dropdown-border: $ember-power-select-default-focus-border;
$ember-power-select-search-field-border: $ember-power-select-default-border;
$ember-power-select-search-field-focus-border: $ember-power-select-default-focus-border;
$ember-power-select-dropdown-top-border: $ember-power-select-dropdown-border;
$ember-power-select-dropdown-right-border: $ember-power-select-dropdown-border;
$ember-power-select-dropdown-bottom-border: $ember-power-select-dropdown-border;
$ember-power-select-dropdown-left-border: $ember-power-select-dropdown-border;
$ember-power-select-dropdown-contiguous-border: none;
$ember-power-select-multiple-option-border-color: $c-mid;
$ember-power-select-multiple-option-border: 1px solid $ember-power-select-multiple-option-border-color; // Borders radius
$ember-power-select-default-border-radius: 0; // General border radius
$ember-power-select-trigger-default-border-radius: $ember-power-select-default-border-radius;
$ember-power-select-dropdown-default-border-radius: $ember-power-select-default-border-radius;
$ember-power-select-opened-border-radius: 0; // Border radius of the side of the dropdown and the trigger where they touch
$ember-power-select-search-input-border-radius: 0;
$ember-power-select-multiple-option-border-radius: $ember-power-select-default-border-radius; // Other
$ember-power-select-focus-box-shadow: none;
$ember-power-select-dropdown-margin: 0; // Margin between the dropdown and the trigger
$ember-power-select-dropdown-box-shadow: none;
$ember-power-select-line-height: (($baseline*1.5)-2px);
$ember-power-select-trigger-icon-color: $ember-power-select-border-color;
$ember-power-select-clear-btn-margin: ($baseline*1.5);
$ember-power-select-option-padding: ($baseline*0.5);
$ember-power-select-number-of-visible-options: 7;
$ember-power-select-focus-outline: null;
$ember-power-select-trigger-ltr-padding: 0 (($baseline*1.5)-2px) 0 0;
$ember-power-select-trigger-rtl-padding: 0 0 0 ($baseline*0.5);
$ember-power-select-multiple-option-padding: 0 ($baseline*0.125);
$ember-power-select-multiple-option-line-height: ($baseline*1.5); // @import "../ember-power-select";

.uxs-form__input {
  &--dropdown {
    max-width: $max-width-content;
    min-width: rs(4);
  }
}

.ember-power-select-status-icon {
  right: ((($baseline*1.5)-2px)-8px)/2;
}

.ember-power-select-clear-btn {
  text-align    : center;
  text-transform: uppercase;
  line-height   : $ember-power-select-line-height;
  height        : $ember-power-select-line-height; //$ember-power-select-line-height;
  width         : rs(0.5);
  bottom        : 1px;
}

.ember-power-select-search {
  padding: 0;
}

.ember-power-select-placeholder,
.ember-power-select-selected-item {
  margin-left: rs(0.5);
}

.ember-power-select-dropdown {
  font-size: rem-size($s-medium);
}

.ember-power-select-search-input {
  font-size          : rem-size($s-medium);
  border             : none;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding            : 0 rs(0.5);

  &:focus {
    border             : none;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
}

.ember-power-select-option {
  color        : $c-dark;
  line-height  : rs(1.5);
  margin-bottom: 0;

  &[aria-current="true"],
  &[aria-selected="true"] {
    color: $c-dark;
  }
}

.ember-power-select-multiple-options {
  min-height   : $ember-power-select-multiple-option-line-height;
  margin-bottom: 0!important;
  padding      : rs(0.0625);
}

.ember-power-select-trigger-multiple-input {
  line-height: $ember-power-select-multiple-option-line-height;
  padding    : 0 rs(0.5, 1);
}

.ember-power-select-multiple-option {
  border     : none;
  line-height: rs(1.25);
  margin     : rs(0.0625); //rem-size(2px) rem-size(1px);
  padding    : 0 rs(.5) 0 0;
}

.ember-power-select-multiple-remove-btn {
  display   : inline-block;
  text-align: center;
  width     : rs(0.25);
  margin    : 0 rs(0.25);
}

.uxs-form__input {
  @each $modifier, $color in $defaultColors {
    &--#{$modifier} {
      .ember-power-select-trigger {
        background  : contrast-color($color);
        border-color: $color;
        color       : $color;
      }

      .ember-power-select-status-icon {
        border-color: $color transparent transparent transparent;
      }

      .ember-power-select-multiple-option {
        background-color: $color;
        color           : contrast-color($color);
      }
    }
  }
}

.uxs-form__dropdown {
  @each $modifier, $color in $defaultColors {
    &--#{$modifier} {
      &.ember-power-select-dropdown {
        background  : contrast-color($color);
        border-color: $color;
        color       : contrast-color(contrast-color($color));
      }

      .ember-power-select-search-input {
        background  : contrast-color($color); //shade-light($color, 4); //
        border-color: $color;
        color       : rgba(contrast-color(contrast-color($color)), 0.6);
      }

      .ember-power-select-option {
        background-color: contrast-color($color);
        color           : contrast-color(contrast-color($color));

        &[aria-current="true"] {
          background-color: rgba($color, 0.4);
          color           : contrast-color(lighten($color,40));
        }

        &[aria-selected="true"] {
          background-color: $color;
          color           : contrast-color($color);
        }
      }
    }
  }
}

.ember-basic-dropdown-trigger {
  &:focus {
    outline: none;
  }
}