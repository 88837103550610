.bg-dark {
  background-color: $c-dark;
}

.bg-grey {
  background-color: $c-grey;
}

.bg-mid {
  background-color: $c-mid;
}

.bg-light {
  background-color: $c-light;
}

.bg-white {
  background-color: $c-white;
}

.bg-p {
  background-color: $c-primary;
}

.bg-p1 {
  background-color: $c-primary-1;
}

.bg-p2 {
  background-color: $c-primary-2;
}

.bg-p3 {
  background-color: $c-primary-3;
}

.bg-p4 {
  background-color: $c-primary-4;
}

.bg-p5 {
  background-color: $c-primary-5;
}

.bg-a {
  background-color: $c-accent;
}

.bg-a1 {
  background-color: $c-accent-1;
}

.bg-a2 {
  background-color: $c-accent-2;
}

.bg-a3 {
  background-color: $c-accent-3;
}

.bg-a4 {
  background-color: $c-accent-4;
}

.bg-a5 {
  background-color: $c-accent-5;
}

.bg-s {
  background-color: $c-success;
}

.bg-s1 {
  background-color: $c-success-1;
}

.bg-s2 {
  background-color: $c-success-2;
}

.bg-s3 {
  background-color: $c-success-3;
}

.bg-s4 {
  background-color: $c-success-4;
}

.bg-s5 {
  background-color: $c-success-5;
}

.bg-e {
  background-color: $c-error;
}

.bg-e1 {
  background-color: $c-error-1;
}

.bg-e2 {
  background-color: $c-error-2;
}

.bg-e3 {
  background-color: $c-error-3;
}

.bg-e4 {
  background-color: $c-error-4;
}

.bg-e5 {
  background-color: $c-error-5;
}

.bg-w {
  background-color: $c-warning;
}

.bg-w1 {
  background-color: $c-warning-1;
}

.bg-w2 {
  background-color: $c-warning-2;
}

.bg-w3 {
  background-color: $c-warning-3;
}

.bg-w4 {
  background-color: $c-warning-4;
}

.bg-w5 {
  background-color: $c-warning-5;
}