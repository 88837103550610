// p {
//   @extend .content;
//   margin-bottom: rem-size($baseline);
// }
//

a {
  color          : inherit;
  font-style     : normal;
  text-decoration: none;

  &:active,
  &:hover {
    color          : inherit;
    font-style     : normal;
    text-decoration: none;
  }
}

.clear:after {
  content: "";
  display: table;
  clear  : both;
}
//
// b,
// em,
// strong {
//   @include bold-body-type;
//   color          : inherit;
//   text-decoration: none;
//   font-style     : normal;
// }
//
// i {
//   font-style: italic;
// }
//
// code {
//   background-color: $c-light;
//   border          : 1px solid $c-mid;
//   border-radius   : rem-size(3px);
//   color           : $c-error;
//   padding         : rem-size(($baseline - $s-medium - 1px) / 2);
// }