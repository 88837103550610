.c-dark {
  color: $c-dark;
}

.c-mid {
  color: $c-mid;
}

.c-grey {
  color: $c-grey;
}

.c-light {
  color: $c-light;
}

.c-white {
  color: $c-white;
}

.c-p {
  color: $c-primary;
}

.c-p1 {
  color: $c-primary-1;
}

.c-p2 {
  color: $c-primary-2;
}

.c-p3 {
  color: $c-primary-3;
}

.c-p4 {
  color: $c-primary-4;
}

.c-p5 {
  color: $c-primary-5;
}

.c-a {
  color: $c-error;
}

.c-a1 {
  color: $c-error-1;
}

.c-a2 {
  color: $c-error-2;
}

.c-a3 {
  color: $c-error-3;
}

.c-a4 {
  color: $c-error-4;
}

.c-a5 {
  color: $c-error-5;
}

.c-w {
  color: $c-warning;
}

.c-w1 {
  color: $c-warning-1;
}

.c-w2 {
  color: $c-warning-2;
}

.c-w3 {
  color: $c-warning-3;
}

.c-w4 {
  color: $c-warning-4;
}

.c-w5 {
  color: $c-warning-5;
}
