.aside {
  padding  : rs(0.5) rs(0.5) rs(0.25);
  max-width: $max-width-content;
  @include mq(tablet) {
    margin: rs(0.5) rs(0.5) 0;
  }

  &__image-wrapper {
    background    : $c-grey;
    height        : 0;
    margin-bottom : rs(0.25);
    padding-bottom: 100%;
    position      : relative;
  }

  &__image {
    left    : 0;
    position: absolute;
    top     : 0;
    width   : 100%;
  }

  &__title {
    @include type-subheading;
    text-transform: uppercase;
    padding-bottom: rem-size(($baseline*0.25)-1px);
    margin-bottom : rs(0.25);
    border-bottom : rem-size(1px) solid rgba($c-white, 0.25);
    max-width     : none;
  }

  &__item {
    @include type-detail;
    border-bottom : rem-size(1px) solid rgba($c-white, 0.25);
    display       : flex;
    flex-direction: row;
    padding-bottom: rem-size(($baseline*0.25)-1px);
    margin-bottom : rs(0.25);
    max-width     : none;
    text-transform: uppercase;

    &:last-child {
      margin-bottom: 0;
    }

    &--no-border {
      border-bottom : none;
      margin-bottom : rs(0);
      padding-bottom: rs(0);
    }
  }

  &__label {
    margin-right: rs(0.5);
    color       : $c-mid;
    min-width   : rs(3);
  }

  &__value {
    color       : $c-white;
    margin-right: rs(0.5);
  }

  &__link {
    @include transition-all;
    @include text-truncate;
    transition-property: color;
    display            : block;
    color              : $c-accent;
    text-transform     : none;
    text-align         : left;
    width              : 100%;
  }
}