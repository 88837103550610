.uxs-preamble {
  align-items   : center;
  background    : $c-light;
  display       : flex;
  flex-direction: row;
  padding       : rem-size($baseline*0.5);
  width         : 100%;
  @include background-color-modifiers();
  @include mq(tablet) {
    padding: rem-size($baseline);
  }

  &--disabled {
    cursor        : not-allowed;
    pointer-events: none;
    opacity       : 0.6;
  }
}

.uxs-preamble__body {
  flex: 1 1 auto;
}

.uxs-preamble__content {
  @include type-detail;

  &:last-child {
    margin-bottom: 0;
  }
  @include mq(tablet) {
    @include type-content;
  }
}

.uxs-preamble__icon {
  @extend .uxs-icon;
  // flex-basis: auto;
  // padding: rem-size($baseline*0.5) 0;
  &--prefix {
    margin-right: rs(1.0);
  }

  &--suffix {
    margin-left: rs(1.0);
  }
}
// &__icon {
//   align-items : center;
//   color       : $c-grey;
//   display     : flex;
//   margin-right: rem-size($baseline*0.5);
//   @include mq(tablet) {
//     margin-right: rem-size($baseline);
//   }
// }