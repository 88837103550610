@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700');
@import "uxs/tools.functions"; //
/**
 * https://coolors.co/d500f9-00bfa5-fb8b24-d90368-0c0b0d
 **/
$c-primary: #D500F9;
$c-accent : #437ae4;
$c-third  : #00BFA5;
$c-third-2: shade-light($c-third, 1);
$c-third-3: shade-light($c-third, 2);
$c-third-4: shade-light($c-third, 3);
$c-third-5: shade-light($c-third, 4);
$c-success: #8FC93A;
$c-error  : #D90368;
$c-warning: #FB8B24;
$c-dark   : #0C0C0C; //#0C0B0D;
$c-grey   : shade-light($c-dark, 1, 10);
$c-mid    : shade-light($c-dark, 5, 10);
$c-light  : shade-light($c-dark, 8, 10);
$c-white  : #FFFFFF;