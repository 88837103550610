.uxs-icon {
  @include transition-all;
  @include material-icon;
  cursor    : pointer;
  position  : relative;
  text-align: center;
  width     : rem-size($baseline);
  @include text-color-modifiers($prefix:'');

  &:focus,
  &:hover {
    opacity: 0.8;

    &::before {
      $size           : rs(1.25);
      border-radius   : 100%;
      background-color: rgba($c-white, 0.1);
      content         : '';
      height          : $size;
      left            : rs(-0.125);
      position        : absolute;
      top             : rs(-0.125);
      min-width       : rs(1);
      min-height      : rs(1);
      width           : $size;
    }
  }

  &--inline {
    float: left;
  }

  &--disabled {
    opacity: 0.6;
    cursor : not-allowed;

    &:focus,
    &:hover {
      opacity: 0.6;
    }
  }
  @include line-height-modifiers;
  $sizes    : (
    "giant" : $baseline*4.0,
    "huge"  : $baseline*3.0,
    "large" : $baseline*2.0,
    "medium": $baseline*1.0,
    "small" : $baseline*0.75,
    "tiny"  : $baseline*0.7,
  );
  @each $modifier, $size in $sizes {
    &--#{$modifier} {
      font-size: rem-size($size);
      width    : rem-size($size);

      &:focus::before,
      &:hover::before {
        $size : rem-size($size+(0.25*$baseline));
        height: $size;
        width : $size;
      }
    }
  }

  &__svg {
    @include fill-color-modifiers;
    @each $modifier, $size in $sizes {
      &--#{$modifier} {
        height: rem-size($size);
        width : rem-size($size);
      }
    }
  }
}