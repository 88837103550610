.uxs-header {
  @include background-color-modifiers();

  &__image {}

  &__body {
    &--with-image {
      background-color: transparent;
    }
  }

  &__toolbar {}

  &__title {}
}