// .dz-file-preview {
//   .dz-details {
//     .dz-filename {
//       span {
//         @include type-detail;
//         display   : inline-block;
//         background: $c-light;
//         height    : rs(1);
//         width     : rs(5);
//       }
//     }
//
//     .dz-size {
//       @include type-detail;
//       display   : inline-block;
//       background: $c-light;
//       height    : rs(1);
//       width     : rs(5);
//     }
//
//     img {
//       display   : inline-block;
//       background: $c-primary;
//       height    : rs(3);
//       width     : rs(3);
//     }
//   }
//
//   .dz-progress {
//     .dz-upload {}
//   }
//
//   .dz-success-mark {
//     span {
//       @include type-detail;
//       display   : inline-block;
//       background: $c-light;
//       height    : rs(1);
//       width     : rs(1);
//     }
//   }
//
//   .dz-error-mark {
//     span {
//       @include type-detail;
//       display   : inline-block;
//       background: $c-light;
//       height    : rs(1);
//       width     : rs(1);
//     }
//   }
//
//   .dz-error-message {
//     span {
//       @include type-detail;
//       display   : inline-block;
//       background: $c-light;
//       height    : rs(1);
//       width     : rs(7);
//     }
//   }
// }

.uxs-breadcrumb__item--detail {
  @include text-truncate;
  max-width: rs(6);
}

.uxs-menu {
  &__text {
    &--detail {
      @include type-detail;
    }
  }
  // Hack where detail modifier overrides the active state
  &__text {
    .active &--detail {
      font-weight: 700;
    }
  }
}
// .uxs-datalist__item {

.uxs-datalist__value--multiline {
  // do not ellisis the content
  max-width    : $max-width-content;
  white-space  : normal;
  overflow     : auto;
  text-overflow: unset;
}
// }

.uxs-navbar__logo-img {
  height: rs(1);
  margin: rs(0.5) auto;
  @include mq(tablet) {
    margin: rs(0.5) 0;
  }
}

.uxs-list-item {
  &__detail {
    max-width: none;

    &--outdated {
      text-decoration: line-through;
    }
  }
}

.uxs-button-group {
  background-color: transparent;
  padding         : rs(0.5) rs(1);

  &__item {
    max-width: none;
    width    : 100%;
  }
}
// Remove default max width from qunit preview

#qunit {
  background: white;

  li,
  ol,
  p,
  ul {
    max-width: none;
  }
}

body {
  background-color: $c-dark;
}

body {
  &.ember-tether-enabled,
  &.liquid-tether-target.liquid-tether-enabled {
    // Stop conten scroll when teather enabled
    overflow: hidden!important;
  }

  .liquid-destination {
    // move the liquid modals below power select content
    z-index: 999;
  }
}

.uxs-liquid-outlet {
  display  : flex;
  flex     : 1 1 auto;
  max-width: 100%;
  overflow : initial;
  // width    : 100%;
  transform: initial;
  width    : 100%;

  > .liquid-child {
    align-items   : center;
    display       : flex;
    flex          : 1 1 auto;
    flex-direction: column;
    max-width     : 100%;
    overflow      : initial;
    transform     : initial;
    width         : 100%;
  }
}

.uxs-app {
  width: 100%;
}
/* Only show the last 3 crumbs on mobile */
.uxs-breadcrumb {
  @include mq(tablet, max) {
    &__item {
      display: none;

      &:nth-last-child(-n+3) {
        display: flex;
      }
    }
  }
}

.uxs-window__pane {
  &--main {
    // bottom padding for fab
    // @include mq(tablet, max) {
    //   padding-bottom: rs(3.5);
    // }
  }

  &--aside {
    background-color: transparent;
    // bottom padding for fab
    @include mq(tablet) {
      padding-bottom: rs(3.5);
    }
    // background-color: $c-grey;
    // background: $c-primary-2;
    // /* fallback for old browsers */
    // background: -webkit-linear-gradient(to left, #9733EE, $c-primary-2);
    // /* Chrome 10-25, Safari 5.1-6 */
    // background: linear-gradient(to left, #9733EE, $c-primary-2);
    // /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
}

.ember-power-select-option {
  line-height: rs(1.5);
}
// NEW Feature for UXS PILLS

.uxs-pill {
  &--text {
    background   : none;
    padding-left : 0;
    padding-right: 0;
  }
}

.uxs-avatar--large {
  height: rs(3);
}

.uxs-text-avatar {
  &--reach {
    &-1 {
      $bg             : shade-light($c-primary, 7, 8);
      background-color: $bg;
      color           : $c-dark; //contrast-color($bg);
      font-size       : $s-small;
      font-weight     : 900;
    }

    &-2 {
      $bg             : shade-light($c-primary, 6, 8);
      background-color: $bg;
      color           : $c-dark; //contrast-color($bg);
      font-size       : $s-small;
      font-weight     : 900;
    }

    &-3 {
      $bg             : shade-light($c-primary, 5, 8);
      background-color: $bg;
      color           : $c-dark; //contrast-color($bg);
      font-size       : $s-small;
      font-weight     : 900;
    }

    &-4 {
      $bg             : shade-light($c-primary, 4, 8);
      background-color: $bg;
      color           : $c-dark; //contrast-color($bg);
      font-size       : $s-small;
      font-weight     : 900;
    }

    &-5 {
      $bg             : shade-light($c-primary, 3, 8);
      background-color: $bg;
      color           : $c-dark; //contrast-color($bg);
      font-size       : $s-small;
      font-weight     : 900;
    }

    &-6 {
      $bg             : shade-light($c-primary, 2, 8);
      background-color: $bg;
      color           : $c-dark; //contrast-color($bg);
      font-size       : $s-small;
      font-weight     : 900;
    }

    &-7 {
      $bg             : shade-light($c-primary, 1, 8);
      background-color: $bg;
      color           : $c-dark; //contrast-color($bg);
      font-size       : $s-small;
      font-weight     : 900;
    }
  }
}

.uxs-fab {
  &--top {
    position: absolute;
    top     : rs(-1.25);
    right   : rs(1);
  }
}

.filter {
  &__overlay {
    background: rgba($c-dark, 0.9);
  }

  &__modal {
    background: $c-dark;
    border    : 1px solid $c-grey;
    color     : $c-white;
    padding   : rs(.5);
    min-height: calc(50vh - #{rs(1.5)});
    min-width : calc(100vw - #{rs(1.5)});
    // max-height: calc(50vh - #{rs(1.5)});
    max-width : calc(100vw - #{rs(1.5)});
    max-height: 100vh;
    overflow  : auto;
    @include mq(tablet) {
      padding   : rs(2);
      min-height: 40%;
      min-width : 40%;
    }
  }

  &__close {
    position: absolute;
    top     : rs(1);
    right   : rs(1);
  }
}

.uxs-button--clipboard {
  margin       : 0 0 0 auto;
  padding-left : rs(.5);
  padding-right: rs(.5);
  width        : auto;
}

.dropzone {
  @include type-content;
  align-items     : center;
  background-color: $c-grey;
  border          : rem-size(1px) solid $c-white;
  display         : flex;
  justify-content : center;
  width           : $max-width-detail;
  min-height      : $max-width-detail;
  position        : relative;
  overflow        : hidden;
  flex-direction  : column;

  &--audio {
    width          : $max-width-content;
    justify-content: flex-start;
    min-height     : rs(4);
  }

  &--file {
    flex-direction : column;
    width          : $max-width-content;
    justify-content: flex-start;
    min-height     : rs(4);
  }

  &__audio-preview {
    align-items    : center;
    background     : $c-grey;
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    position       : absolute;
    top            : 0;
    right          : 0;
    bottom         : 0;
    left           : 0;
  }

  &__file-preview {
    align-items    : center;
    background     : $c-grey;
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    height         : rs(4);
    width          : 100%;
    position       : relative;
  }

  &__icon {
    margin: 0 rs(.5);
  }

  &__meta {
    flex         : 1 1 auto;
    padding-right: rs(1);
  }

  &__details,
  &__filename {
    @include type-detail;
    max-width    : none;
    margin-bottom: 0;
    margin-bottom: 0;
  }

  &__length,
  &__size {
    padding-right: rs(.5);
  }

  &__link {
    border-bottom: none!important;
    color        : $c-primary!important;
    float        : right;
  }

  &__uploading {
    align-items    : center;
    background     : $c-grey;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    position       : absolute;
    top            : 0;
    right          : 0;
    bottom         : 0;
    left           : 0;

    &-text {
      align-items    : center;
      display        : flex;
      justify-content: center;
      flex           : 1 1 auto;
    }
  }

  &__progress {
    background: rgba($c-dark, .25);
    display   : none;
    bottom    : 0;
    height    : rs(.5);
    position  : absolute;
    left      : 0;
    width     : 100%;

    &-bar {
      background: $c-accent;
      height    : rs(.5);
      width     : 0;
    }
  }

  &__error {
    @include type-detail;
    background: $c-error;
    bottom    : 0;
    color     : $c-white;
    display   : none;
    position  : absolute;
    left      : 0;
    width     : 100%;
    margin    : 0;
    max-width : none;
    padding   : 0 rs(0.5);
  }

  .dropzone-image {
    position: absolute;
    top     : 0;
    right   : 0;
    bottom  : 0;
    left    : 0;
  }

  .dz-details {
    @include type-detail;
    display        : flex;
    flex-direction : row-reverse;
    padding        : rs(0.5);
    display        : flex;
    justify-content: space-between;
    max-width      : none;

    .dz-size {
      text-align: right;
    }
  }

  .dz-error-message {
    background: $c-error;
    color     : $c-white;
    overflow  : hidden;
    padding   : rs(0.5);
  }

  .dz-message {
    align-items    : center;
    display        : flex;
    justify-content: center;
    text-align     : center;
    min-height     : rs(4);
    width          : 100%;
  }

  .dz-preview {
    border: rem-size(1px) solid $c-white;
    height: 100%;
    width : 100%;
  }

  .dz-remove {
    @include type-detail;
    padding    : 0 rs(1);
    color      : $c-primary;
    font-weight: 700;
  }

  &.dz-started {
    border: none;

    .dz-message {
      display: none;
    }
  }

  .dz-error-mark,
  .dz-success-mark {
    display: none;
  }
}

.dz-processing {
  .dropzone__progress {
    display: block;
  }
}

.dz-error {
  .dropzone__error {
    display: block;
  }
}

#dropzone-template .dropzone__file-preview {
  display: none;
}

.load-bar {
  position        : relative;
  width           : 100%;
  height          : rs(.25);
  background-color: $c-dark;
}

.bar {
  content   : "";
  display   : inline;
  position  : absolute;
  width     : 0;
  height    : 100%;
  left      : 50%;
  text-align: center;
}

.bar:nth-child(1) {
  background-color: $c-primary;
  animation       : loading 2s linear infinite;
}
@keyframes loading {
  from {
    left   : 50%;
    width  : 0;
    z-index: 100;
  }

  100% {
    left   : 0;
    width  : 100%;
    z-index: 10;
  }

  to {
    left : 0;
    width: 100%;
  }
}

.app-footer {
  display        : flex;
  align-items    : center;
  justify-content: flex-start;
  width          : 100%;
  padding        : 0 rs(0.5);
  margin-top     : rs(2);
  @include mq(tablet) {
    justify-content: center;
    padding        : 0;
  }
}

.ember-power-select-trigger {
  background-color: $c-dark;
  border-radius   : 0;
  min-height      : rs(1.5);
}

.ember-power-select-clear-btn {
  bottom: 0;
}

.ember-power-select-selected-item {
  line-height: rs(1.5, 2);
  height     : rs(1.5, 2);
  display    : inline-block;
}

.uxs-form__input {
  &--date,
  &--datetime,
  &--time {
    background: $c-dark;
    color     : $c-white;
  }
}
