.uxs-search {
  display       : flex;
  flex-direction: row;

  &__close {
    margin: 0;
  }

  &__wrapper {
    @include transition-all;
    display       : flex;
    flex-direction: row;
    max-width     : 0;

    &--open {
      max-width: rem-size($baseline*10);
    }
  }

  &__open {}

  &__input {
    background-color: transparent;
    border          : none;
    color           : $c-white;
    margin-bottom   : 0;
    font-weight     : 400;
    margin          : 0 0 0 rem-size($baseline*0.25);
    padding         : 0;

    &:focus {
      box-shadow: none;
      outline   : none;
    }
  }

  &__close,
  &__input {
    line-height  : rem-size($baseline);
    height       : rem-size($baseline);
    border-bottom: rem-size(1px) solid $c-white;
  }
}