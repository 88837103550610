.uxs-pagination {
  // background-color: $c-light;
  @include background-color-modifiers();
  display        : flex;
  flex-direction : row;
  padding        : rs(0.5);
  justify-content: space-between;
  width          : 100%;
  @include mq(tablet) {
    padding: rs(.5) rs(1);
  }

  &__controls,
  &__info,
  &__result-limit {
    max-width: none;
  }

  &__result-limit {
    display       : flex;
    flex-direction: row;
  }

  &__info,
  &__label {
    margin-bottom: 0;
  }

  &__size {
    @include detail-font-size;
    margin: 0 0 0 rem-size($baseline*0.5);
    height: rem-size($baseline);
  }

  &__info {
    @include text-truncate;
    @include mq(tablet) {
      padding: 0 rem-size($baseline*1);
    }
    flex: 1 1 auto;
  }

  &__controls {}
}