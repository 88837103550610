.uxs-datalist {
  @include text-color-modifiers($prefix:'');
  padding: rs(0.5);
  @include mq(tablet) {
    padding: rs(0.5) rs(1);
  }

  &__item {
    border-bottom-color: $c-light;
    margin-bottom      : rem-size($baseline*1);
    max-width          : 100%;
    overflow           : auto;
    @include contrast-border-color-modifiers($dark-color:rgba($c-light,0.4), $light-color:rgba($c-dark,0.4),$prefix:'');

    &--bordered {
      border-bottom-width: rem-size(1px);
      border-bottom-style: solid;
      padding-bottom     : rem-size(($baseline*0.5));
      margin-bottom      : rem-size(($baseline*0.5)-1px);
    }
  }

  &__label {
    @include label;
    @include text-truncate;
  }

  &__row {
    display       : flex;
    flex-direction: row;
    padding-left  : rem-size($baseline*0.5);
  }

  &__group {
    display       : flex;
    flex-direction: column;
    padding-left  : rem-size($baseline*0.5);
  }

  &__value {
    @include type-content;
    @include text-truncate;
  }

  &__tip {
    @include type-detail;
    @include text-truncate;
  }
}