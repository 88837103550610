.uxs-list-item {
  @include mq(tablet, max) {
    margin-left : rs(0.25);
    margin-right: rs(0.25);

    &__aside:first-child {
      flex-shrink: 0;
    }
  }
}

.uxs-grid {
  display       : flex;
  padding       : rs(0.25);
  flex-direction: row;
  flex-wrap     : wrap;
  padding-top   : 0;
  margin-top    : rs(-0.25);
  @include mq(tablet) {
    padding   : rs(0.5);
    margin-top: rs(-0.5);
  }

  &__item {
    margin: rs(0.25);
    width : calc(50% - #{rs(0.5)});
    @include mq(tablet) {
      margin: rs(0.5);
    }
    @include mq(tablet) {
      width: calc(25% - #{rs(1)});
    }
    @include mq(desktop) {
      width: calc(20% - #{rs(1)});
    }
    @include mq(desktop-wide) {
      width: calc(16.667% - #{rs(1)});
    }
  }

  &__body {
    background    : $c-dark;
    padding-bottom: 100%;
    height        : 0;
  }

  &__aside {
    background: $c-grey;
    clear     : right;
    overflow  : auto;
    padding   : rs(0.5);
    position  : relative;
  }

  &__title {
    @include type-subheading;
    padding-right: rs(1);
    @include text-truncate;
  }

  &__content {
    @include type-content;
    @include text-truncate;
  }

  &__detail {
    @include type-detail;
    @include text-truncate;
  }

  &__action {
    position: absolute;
    top     : rs(0.5);
    right   : rs(0.5);
  }
}