table.table--no-margin {
  margin: 0;
}

table.detail {
  max-width: none;

  td,
  th {
    @extend .detail;
  }
}

.table-section {
  td {
    padding-top: rem-size($baseline*1);
  }
}

.table-emphasis {
  td {
    @include bold-body-type;
  }
}

.table-wrap {
  white-space: normal;
}

.uxs-table {
  @include background-color-modifiers;
  @include body-type;
  @include content-font-size;
  @include font-size-modifiers;
  @include line-height-modifiers;
  @include text-color-modifiers;

  &--record {
    border-collapse: collapse;
    table-layout   : fixed;
    margin         : 0;
    max-width      : none;
    width          : 100%;
    white-space    : nowrap;
  }

  &--padding {
    tr:first-child td,
    tr:first-child th {
      padding-top: rs(0.5);
    }

    tr:last-child td,
    tr:last-child th {
      padding-bottom: rs(0.5);
    }
  }

  &--full-width {
    width: 100%;
  }

  &--detail {
    @include detail-font-size;
  }

  &__row {
    &--record {
      @include transition(background);

      &:hover:not(:first-child) {
        background : $c-light;
        cursor     : pointer;
        font-weight: 400;
      }
    }
  }

  &__heading {
    &--record {
      border-bottom : rem-size(1px) solid $c-light;
      color         : $c-mid;
      padding-bottom: rs(1.0);
    }

    &--numeric {
      text-align: right;
    }
  }

  &__cell {
    &--section {
      padding-top: rem-size($baseline);
    }

    &--numeric {
      text-align: right;
    }

    &--highlight {
      @include bold-body-type;
    }

    &--record {
      border-bottom: rem-size(1px) solid $c-light;
      line-height  : rs(2.0);
    }
  }

  &__cell,
  &__heading {
    &--record {
      white-space  : nowrap;
      overflow     : hidden;
      text-overflow: ellipsis;
      width        : auto;
    }

    &--small {
      width: rs(1.5);
    }

    &:first-child {
      padding-left: rs(0.5);
    }

    &:last-child {
      padding-right: rs(0.5);
    }
  }
}