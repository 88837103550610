@keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes slideInLeft {
  0% {
    left: -80%;
  }

  100% {
    left: 0;
  }
}
@-webkit-keyframes opacityPulse {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 1.0;
  }

  100% {
    opacity: 0.6;
  }
}
@keyframes opacityPulse {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 1.0;
  }

  100% {
    opacity: 0.6;
  }
}