.uxs-notice {
  @include type-content;
  @include background-color-modifiers();
  background     : $c-mid;
  clear          : both;
  color          : $c-white;
  display        : flex;
  flex-direction : row;
  margin-bottom  : rem-size($baseline);
  max-width      : 100%;
  padding        : 0 rem-size($baseline*0.5);
  width          : 100%;
  align-items    : flex-start;
  justify-content: space-between;

  &--truncated {
    @include text-truncate
  }

  &--has-text {
    display: block;
    padding: rem-size($baseline*0.5);
  }

  &--disabled {
    cursor        : not-allowed;
    pointer-events: none;
    opacity       : 0.6;
  }
}

.uxs-notice__text {
  flex   : 1 1 auto;
  padding: rem-size($baseline*0.5) 0;

  &--truncated {
    @include text-truncate
  }
}

.uxs-notice__icon {
  @extend .uxs-icon;
  flex-basis: auto;
  padding   : rem-size($baseline*0.5) 0;

  &--prefix {
    margin-right: rs(0.5);
  }

  &--suffix {
    margin-left: rs(0.5);
  }
}

.uxs-notice__button {
  margin-bottom: rs(0.25);
  margin-top   : rs(0.25);

  &--prefix {
    margin-right: rs(0.5);
  }

  &--suffix {
    margin-left: rs(0.5);
  }
}