.uxs-hero {
  position: relative;
  color   : white;

  &__image {
    background-color   : $c-primary;
    background-position: center;
    background-repeat  : no-repeat;
    background-size    : cover;
    padding-bottom     : 60%;
    width              : 100%;
  }

  &__body {
    align-items    : center;
    background     : rgba($c-dark, .5);
    bottom         : 0;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    left           : 0;
    position       : absolute;
    right          : 0;
    top            : 0;
  }

  &__heading {}

  &__content {}

  &__button {
    margin-top: rem-size($baseline);
  }
}