.Chart,
.ChartControl {
  float: left;
  width: 100%;

  &__content {
    @include card-shadow(2);
    background: $c-white;
    border-top: rs(0.25) solid $c-primary;
    margin    : 0;

    image-orientation:mq(tablet) {
      min-height: 19rem;
    }

    image-orientation:mq(desktop) {
      min-height: 22rem;
    }
    @include mq(desktop) {
      min-height: rs(21);
    }
  }
  // Teal
  &--teal &__content {
    border-top-color: $c-third;
  }
  // Blue
  &--blue &__content {
    border-top-color: $c-accent;
  }
  // Pink
  &--pink &__content {
    border-top-color: $c-primary-3;
  }
}

.Chart {
  @include mq(desktop) {
    margin-bottom: 1rem;
    width        : 70%;
  }

  &__content {
    // display : flex;
    overflow: auto;
    padding : rs(.75) rs(0) rs(1);
    position: relative;
    @include mq(desktop) {
      margin-right: rs(1);
      padding     : rs(1.25) rs(1.5) rs(1.5);
    }
    @include mq(tablet) {
      &--large {
        min-height: 525px;
      }
    }
  }

  &__loader {
    // margin-top: -2rem;
    // position  : absolute;
    // text-align: center;
    // top       : 50%;
    // width     : 100%;
    align-items    : center;
    display        : flex;
    justify-content: center;
    flex           : 1 1 auto;
    width          : 100%;
  }

  &__loader-text {
    // text-align: center;
    // width     : 100%;
    // margin-top: 0.5rem;
  }

  &__spinner {
    height: rs(3);
    margin: rs(5) 0;
    width : rs(3);
    stroke: $c-dark;

    &--primary {
      stroke: $c-primary;
    }

    &--teal {
      stroke: $c-third;
    }
    // Blue
    &--blue {
      stroke: $c-accent;
    }
    // Pink
    &--pink {
      stroke: $c-primary-3;
    }
  }

  &__title {
    @include type-heading;
    box-sizing: border-box;
    color     : $c-dark;
    //font-weight: 100;
    margin    : 0;
    text-align: center;
    z-index   : 2;

    &--left,
    &--right {
      padding : 1rem;
      position: absolute;
      top     : 0;
      width   : 50%;
    }

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  &__chart {
    width: auto;
  }

  &__chart--half {
    float: left;
    width: 50%;
  }
  // Teal
  &--teal &__title {
    color: $c-third;
  }
  // Blue
  &--blue &__title {
    color: $c-accent;
  }
  // Pink
  &--pink &__title {
    color: $c-primary-3;
  }

  &--teal {}

  &--blue {}

  &--pink {}
}

.ChartControl {
  display       : flex;
  flex-direction: column;
  position      : relative;
  margin-bottom : rs(1);
  @include mq(desktop) {
    width: 30%;
  }

  &__content {
    @include mq(tablet) {
      // padding: 0 0 rs(2);
    }
    @include mq(desktop) {
      // margin-left: rs(0.5);
      &--large {
        min-height: 30rem;
      }
    }
  }

  &__title {
    @include subheading-font-size;
    @include light-body-type;
    background: $c-primary;
    color     : $c-white;
    margin    : 0;
    padding   : rs(0.75) rs(1) rs(1);
    text-align: right;
    @include mq(desktop) {
      @include heading-font-size;
      line-height: rs(2);
      margin     : 0;
      text-align : right;
      padding    : rs(0.75) rs(1) rs(4);
    }
  }

  &__select {
    position : absolute;
    top      : rs(1);
    right    : rs(1);
    left     : rs(1);
    max-width: 50%;
    height   : rs(1.5);
    overflow : hidden;
    @include mq(desktop) {
      top      : rs(3);
      height   : auto;
      overflow : auto;
      max-width: 100%;
    }

    .ember-power-select-trigger {
      background: transparent;
      border    : none;
    }
  }

  &__button {
    color: $c-primary;
  }

  &__footer {
    padding   : 0 0 rs(0.5);
    text-align: center;
  }
  // Teal
  &--teal &__title {
    background: $c-third;
  }

  &--teal &__button {
    color: $c-third;
  }
  // Blue
  &--blue &__title {
    background: $c-accent;
  }

  &--blue &__button {
    color: $c-accent;
  }
  // Lime
  &--pink &__title {
    background: $c-primary-3;
  }

  &--pink &__button {
    color: $c-primary-3;
  }
}

.ChartMeta {
  color         : $c-primary;
  display       : flex;
  flex-direction: row;
  padding       : rs(0.5);
  position      : relative;
  min-height    : rs(4);
  align-items   : center;
  @include mq(desktop) {
    padding: rs(0.5) rs(1);
  }

  &--action {
    cursor: pointer;
  }

  &__icon {
    background   : $c-primary;
    border-radius: 100%;
    color        : $c-white;
    padding      : rs(0.5);
    margin-right : rs(0.5);
    height       : rs(2);
    width        : rs(2);
    // top          : 1rem;
    cursor       : default;

    &[data-ember-action] {
      cursor: pointer;
    }

    &:before {
      display: none;
    }
  }

  &__label,
  &__value {
    display: block;
  }

  &__label {
    @include type-content;
  }

  &__value {
    @include type-subheading;
  }

  &__meta {
    @include type-detail;
  }
  // Teal
  &--teal {
    color: $c-third;
  }

  &--teal &__icon {
    background: $c-third;
  }
  // Blue
  &--blue {
    color: $c-accent;
  }

  &--blue &__icon {
    background: $c-accent;
  }
  // Blue
  &--pink {
    color: $c-primary-3;
  }

  &--pink &__icon {
    background: $c-primary-3;
  }
}