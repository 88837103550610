.uxs-tiles {
  &__group {
    justify-content: center;
    display        : flex;
    flex-direction : row;
    flex-wrap      : wrap;
    padding        : 0 0 rs(0.5);
    @include mq(tablet) {
      flex-direction: row;
      padding       : 0 0 rs(1);
    }

    &--scroll {
      position: relative;
      @include mq(tablet) {
        &::after,
        &::before {
          content     : "";
          border-style: solid;
          height      : inherit;
          top         : calc(50% - #{rs(1)});
          opacity     : 0.5;
          position    : absolute;
          width       : inherit;
          z-index     : 2;
        }

        &::before {
          border-width: rs(0.5) rs(0.5) rs(0.5) 0;
          border-color: transparent $c-dark transparent transparent;
          left        : rs(.25);
        }

        &::after {
          border-width: rs(0.5) 0 rs(0.5) rs(0.5);
          border-color: transparent transparent transparent $c-dark;
          right       : rs(.25);
        }
        @each $modifier, $color in $defaultColors {
          &.uxs-tiles__group--#{$modifier} {
            &::before {
              border-color: transparent contrast-color($color) transparent transparent;
            }

            &::after {
              border-color: transparent transparent transparent contrast-color($color);
            }
          }
        }

        &:focus::after,
        &:focus::before,
        &:hover::after,
        &:hover::before {
          opacity: 1;
        }
      }
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  &__group-scroll {
    display  : flex;
    flex-wrap: wrap;
    @include mq(tablet) {
      align-items: center;
      flex-wrap  : nowrap;
      overflow-x : auto;
      position   : relative;
      // .uxs-tiles__item {
      //   flex: 0 0 auto;
      // }
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__item {
    @include background-color-modifiers;
    // margin     : rs(0.5);
    flex-grow     : 0;
    flex-shrink   : 0;
    z-index       : 2;
    // box-shadow    : 0 4px 10px 0 rgba($c-dark,0.20);
    display       : flex;
    flex-direction: column;
    position      : relative;
    z-index       : 1;

    &:first-child,
    &:last-child {
      z-index: 3;
    }

    &--full {
      margin  : 0 0 rs(0.5);
      position: absolute;
      top     : rs(-6);
      flex    : 1 1 auto;
      height  : calc(100vh - (#{rs(5.5, 5px)}));
      width   : calc(100vw - #{rs(1)});
      @include mq(tablet) {
        margin     : 0 rs(1) 0 0;
        flex-grow  : 0;
        flex-shrink: 0;
        // 1/3 width with 2rem padding at row ends and another two seperating margins
        height     : calc(100vh - (#{rs(5.5, 5px)}));
        width      : calc(100vw - (#{rs(2)}));
      }
    }

    &--large {
      margin: 0 0 rs(0.5);
      flex  : 1 1 auto;
      height: calc(60vw - #{rs(1)});
      width : calc(100vw - #{rs(1)});
      @include mq(tablet) {
        margin     : 0 rs(1) 0 0;
        flex-grow  : 0;
        flex-shrink: 0;
        // 1/2 width with 2rem padding at row ends and another two seperating margins
        height     : calc(20vw - (#{rs(4/3)}));
        width      : calc(50vw - (#{rs(1.5)}));
      }
      //- #{rs(1.25)}
    }

    &--medium {
      margin: 0 0 rs(0.5);
      flex  : 1 1 auto;
      height: calc(60vw - #{rs(1)});
      width : calc(100vw - #{rs(1)});
      @include mq(tablet) {
        margin     : 0 rs(1) 0 0;
        flex-grow  : 0;
        flex-shrink: 0;
        // 1/3 width with 2rem padding at row ends and another two seperating margins
        height     : calc(20vw - (#{rs(4/3)}));
        width      : calc(33.3vw - (#{rs(4/3)}));
      }
      //- #{rs(1.25)}
    }

    &--small {
      height: calc(50vw - (#{rs(1.5/2)}));
      width : calc(50vw - (#{rs(1.5/2)}));
      margin: 0 rs(0.5) rs(0.5) 0;
      // flex      : 1 1 0;
      &:nth-child(even) {
        margin-right: 0;
      }
      @include mq(tablet) {
        // 1/5 width with 2rem padding at row ends and another 4 seperating margins
        height: calc(20vw - (#{rs(6/5)}));
        width : calc(20vw - (#{rs(6/5)}));
        margin: 0 rs(1) 0 0;

        &:nth-child(even) {
          margin-right: rs(1);
        }
      }
    }

    &:nth-child(even) {
      // opacity: 0.5;
    }

    &:last-child {
      margin-bottom: 0;
      margin-right : 0;
    }
  }
}