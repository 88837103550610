@import 'reset.scss';
@import 'https://fonts.googleapis.com/css?family=Lato:300,400,700|Material+Icons';
@import 'https://use.typekit.net/rjj5vdz.css';

html {
  min-height: 100vh;
}
// Disable the close icon on inputs in IE

::-ms-clear {
  display: none;
}