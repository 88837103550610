.uxs-window {
  @include background-color-modifiers();
  display       : flex;
  flex-direction: column;
  padding       : 0;
  flex          : 1 1 auto;
  width         : 100%;
  @include mq(tablet) {
    flex-direction: row;
  }

  &--splash {
    align-items        : center;
    background-position: center;
    background-repeat  : no-repeat;
    background-size    : cover;
    justify-content    : center;
    padding            : 0;
  }

  &__pane {
    @include background-color-modifiers();
    padding       : 0;
    display       : flex;
    flex-direction: column;

    &--padded {
      padding: rem-size($baseline*1) rem-size($baseline*0.5);
      @include mq(tablet) {
        padding: rem-size($baseline*1);
      }
    }

    &--full,
    &--single {
      width: 100%;
    }

    &--main {
      width: 100%;
      @include mq(tablet) {
        width: 66.7%;
      }
      @include mq(desktop) {
        width: 75%;
      }
    }

    &--splash {
      padding  : rem-size($baseline);
      margin   : rem-size($baseline*2) 0;
      max-width: $max-width-content;
      width    : 90%;
      @include mq(tablet) {
        width: 40%;
      }
    }

    &--split {
      width: 100%;
      @include mq(tablet) {
        width: 50%;
      }
    }

    &--aside,
    &--detail,
    &--index {
      width: 100%;
      @include mq(tablet) {
        width: 50%;
      }
      @include mq(desktop) {
        width: 33.333%;
      }
    }

    &--index {
      display: none;
      @include mq(tablet) {
        display: block;
      }
    }

    &--aside {
      width: 100%;
      @include mq(tablet) {
        width: 33.3%;
      }
      @include mq(desktop) {
        width: 25%;
      }
    }
    @include mq(tablet, 'max') {
      // flex to fill on mobile as panes are in one column;
      flex: 1 1 auto;

      &--mobile-order-1 {
        order: 1;
      }

      &--mobile-order-2 {
        order: 2;
      }

      &--mobile-order-3 {
        order: 3;
      }

      &--mobile-order-4 {
        order: 4;
      }

      &--mobile-order-5 {
        order: 5;
      }

      &--mobile-order-6 {
        order: 6;
      }

      &--mobile-order-7 {
        order: 7;
      }

      &--mobile-order-8 {
        order: 8;
      }

      &--mobile-order-9 {
        order: 9;
      }
    }
  }
}
// Archive form overrides

.uxs-window {
  &__pane {
    &--archive-form {}

    &--archive-icon {
      align-items    : center;
      justify-content: flex-start;
      flex           : none;
      padding        : rs(1);
      @include mq(tablet) {
        flex   : 1 1 1 auto;
        padding: rs(2) rs(0);
      }
    }
  }
}

.uxs-icon {
  &--archive-icon {
    font-size  : rs(6);
    line-height: rs(6);
    width      : rs(6);
  }
}