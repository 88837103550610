.uxs-splash {
  align-items    : center;
  display        : flex;
  flex-direction : column;
  justify-content: center;
  margin         : rs(2) auto;
  max-width      : $max-width-content;
  padding        : rs(1);

  &--left {
    margin-left : 0;
    margin-right: auto;
  }

  &--center {
    margin-left : auto;
    margin-right: auto;
  }

  &--right {
    margin-left : auto;
    margin-right: 0;
  }

  &__icon {
    margin: 0 0 rs(0.5);
  }

  &__title {
    @extend .subheading;
    color : $c-grey;
    margin: 0 0 rs(0.5);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__text {
    @extend .content;
    color: $c-grey;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__icon,
  &__text,
  &__title {
    @include align-modifiers;
    @include weight-modifiers;
    @include text-color-modifiers($prefix:'');
  }
}