.uxs-button-group {
  display       : flex;
  flex-direction: column;
  padding       : rs(0.5) 0;
  @include background-color-modifiers();

  &--padded {
    padding: rs(0.5) rs(1);
  }

  &--center {
    align-items: center;
  }

  &--left {
    align-items: flex-start;
  }

  &--right {
    align-items: flex-end;
  }

  &__item {
    margin-bottom: 0;

    &:last-child {
      margin-bottom: rs(0.5);
    }
  }

  &--row {
    @include mq(tablet) {
      flex-direction: row;

      .uxs-button-group__item,
      .uxs-button-group__item:last-child {
        margin-bottom: 0;
      }

      > * {
        margin: 0 rs(0.25);

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}