.mobile-actions {
  &__trigger {
    position: absolute;
    right   : rs(0.5);
    bottom  : rs(1.25);
    @include mq(tablet) {
      display: none;
    }
  }
}

.mobile-hide {
  @include mq(tablet, max) {
    display: none;
  }
}

.uxs-header__title {
  @include mq(tablet, max) {
    margin-left : rs(.5);
    margin-right: rs(1.5);
  }
}

.uxs-toolbar__detail {
  @include mq(tablet, max) {
    @include text-truncate;
    width: calc(100vw - #{rs(4)});
  }
}