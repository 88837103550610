.uxs-header {
  min-height: rem-size($baseline*10);
  overflow  : auto;
  position  : relative;
  width     : 100%;

  &__image {
    @include background-color-modifiers();
    background-position: center;
    background-repeat  : no-repeat;
    background-size    : cover;
    bottom             : 0;
    left               : 0;
    position           : absolute;
    right              : 0;
    top                : 0;
    z-index            : 2;
  }

  &__logo {
    height    : rs(8);
    text-align: center;

    &-svg {
      height: 100%;
    }

    &-img {
      height: 100%;
    }
  }

  &__body {
    min-height: rem-size($baseline*10);
    overflow  : auto;
    position  : relative;
    z-index   : 2;

    &--with-image {}
  }

  &__toolbar {
    background: transparent;
    color     : inherit;
    min-height: rs(2);
  }

  &__title {
    @include text-truncate;
    @include type-heading;
    margin  : rs(5) rs(0.5) rs(1);
    position: relative;
    @include mq(tablet) {
      font-size  : rem-size($s-huge);
      line-height: rem-size($baseline*2);
      margin     : rs(5) rs(1) rs(1);
    }
  }

  &__mobile-menu-trigger {
    align-items    : center;
    height         : rs(1.5);
    top            : 0;
    right          : 0;
    justify-content: center;
    position       : absolute;
    @include mq(tablet) {
      display: none;
    }
  }
}