/*
 * Margin helper classes
 */
.m {
  margin: rem-size($baseline);

  &--nm {
    margin: 0;
    @include mq(phone) {
      margin: rem-size($baseline);
    }
  }
}

.mt {
  margin-top: rem-size($baseline);

  &--nm {
    margin-to: 0;
    @include mq(phone) {
      margin-to: rem-size($baseline);
    }
  }
}

.mr {
  margin-right: rem-size($baseline);

  &--nm {
    margin-right: 0;
    @include mq(phone) {
      margin-right: rem-size($baseline);
    }
  }
}

.mb {
  margin-bottom: rem-size($baseline);

  &--nm {
    margin-bottom: 0;
    @include mq(phone) {
      margin-bottom: rem-size($baseline);
    }
  }
}

.ml {
  margin-left: rem-size($baseline);

  &--nm {
    margin-left: 0;
    @include mq(phone) {
      margin-left: rem-size($baseline);
    }
  }
}
